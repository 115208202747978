.bg-grad {
  background-image: linear-gradient(
    135deg,
    #ffbc7d 0%,
    #ff9736 50%,
    #ff7a00 100%
  );
}

.font-sans {
  font-family: sans-serif;
}

.search-bar {
  border: 2px solid #ff7a00;
  /* width: 40vw; */
  padding: 1.2rem;
  border-radius: 10px;
  font-size: 1.5rem;
  margin-top: 3rem;
}

form {
  width: 100%;
  text-align: center;
}

.search-container {
  position: relative;
  width: 100vw;
  max-width: 100%;
  min-height: 80vh;
}

.old-btn {
  background-color: transparent;
  border: 2px solid #ff7a00;
  color: #ff7a00;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.old-btn:hover {
  background-color: #ff7a00;
  color: #fff;
  transition: 0.3s;
  transform: scale(1.1);
  font-weight: 500;
}

.copy-container {
  position: absolute;
  right: 2%;
}

.output-container {
  height: 5rem;
}

.output {
  height: 100%;
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 0px 0px #ff7a00, 0px 0px 0px 0px #ff7a00;
}

svg.copy-icon {
  position: relative;
  right: -4%;
  top: -22px;
}
